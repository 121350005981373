













































import { Vue, Component } from "vue-property-decorator";
import { recruitDetail } from "@/api";
@Component
export default class jobDetail extends Vue {
    public postWelfare = [];
    public jobDetail: any = {
        name: "西南区域经理",
        department: "销售部",
        workPlace: "福建-福州",
        recruits: "20人",
        positionCategoryName: "销售",
        education: "本科",
        workingYears: "5年以上",
        salary: "面议",
        jobDetail: "面议",
        updateTime: "2021-12-01",
    };

    public async mounted() {
        let json = this.$route.query.json.toString();
        this.jobDetail = json && JSON.parse(json);
        this.postWelfare = this.jobDetail.postWelfare ? this.jobDetail.postWelfare.split("、") : [];
        console.log(this.jobDetail);
    }
}
